<template>
  <div class="list-page">
    <el-button class="top-btn" size="small" type="primary" @click="handleAdd(0)">新增</el-button>
    <list-filter v-model="filterForm" :ids="selectArr" :page="pageData.current_page" :uploadFilter="ok" />
    <list-tabs class="table-tab" v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr"
      @tab-click="handleClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab != 'deleted'">
        <el-button size="small" type="danger" @click="handleDel">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab == 'deleted'">
        <el-button size="small" type="success" @click="handleReduct">还原</el-button>
        <el-button size="small" type="danger" @click="handleDel">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选 -->
        <el-table-column class="select-check" fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" :width="filterForm.tab != 'deleted'?'160':'110'" fixed="right">
          <template slot-scope="scope">
            <div v-if="filterForm.tab != 'deleted'">
              <el-button type="text" size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
              <el-button type="text" size="small" @click="deleteRow(scope.row.id)">删除</el-button>
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row.id)">
                <span style="color: #3576ff;font-size:12px" class="el-dropdown-link">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in moreHandleList" :key="index"
                    :command="item.command">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-button-group v-if="filterForm.tab == 'deleted'">
              <el-button type="text" size="small" @click="deleteRow(scope.row.id)">删除</el-button>
              <el-button type="text" size="small" @click="ReductRow(scope.row.id)">还原</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 复选框 -->
              <el-checkbox v-if="th.prop === 'is_show'" v-model="tbody[scope.$index][th.prop]"
                @change="changeStatus(scope.row, th.prop)"></el-checkbox>
              <!-- 排序输入框 -->
              <ListInputPopover v-else-if="th.prop === 'sort'" :integer="true"
                v-model="tbody[scope.$index][th.prop]" @change="changeStatus(scope.row, th.prop)">
              </ListInputPopover>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <el-dialog :loading="saveLoading" title="访问链接" :visible.sync="openDialog" width="500px" :show-close="true"
      class="dialog-vertical" @close="cancel('CategoryName')">
      <div style="display: flex">
        <div>{{qrcode.url}}</div>
        <img :src="qrcode.base64">
      </div>
      <span slot="footer">
        <el-button size="small" @click="openDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="submit(qrcode.url)">复制链接</el-button>
      </span>
    </el-dialog>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import listFilter from "../components/ListFilter";
import ListInputPopover from "@/base/components/List/ListInputPopover.vue";
import Pagination from "@/base/components/Default/Pagination";
import { copyText } from "@/base/utils/tool";

import {
  getIndex,
  getSoftDelete,
  getPutBack,
  getDelete,
  getQrcode,
} from "../api/signup-list";
export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "进行中", name: "running" },
        { label: "已结束", name: "finished" },
        { label: "未开始", name: "not_running" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      openDialog: false,
      saveLoading: false,
      thead: [
        { label: "ID", prop: "id", minWidth: 110 },
        { label: "名称", prop: "title", minWidth: 110 },
        {
          label: "提交总数",
          prop: "sign_count",
          minWidth: 100,
          sortable: true,
        },
        {
          label: "报名时间",
          prop: "time_text",
          minWidth: 280,
          sortable: true,
        },
        { label: "状态", prop: "status_text", minWidth: 100 },
      ],

      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //组织名称
        tab: "normal", //当前筛选tab
        page_size: 15, //每页数据量
      },
      //筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      qrcode: [],
      // 更多下拉操作
      moreHandleList: [
        { name: "信息管理", command: "Administration" },
        { name: "字段配置", command: "toFormConfig" },
        { name: "查看链接", command: "seeRow" },
      ],
    };
  },
  methods: {
    toconfigure() {},
    seeRow(id) {
      this.openDialog = true;
      getQrcode({ id: id })
        .then((res) => {
          this.qrcode = res.data;
          this.saveLoading = true;
        })
        .catch((err) => {});
    },
    //点击更多操作
    handleClickRow(val, id) {
      this[val](id);
    },
    //新增
    handleAdd(id) {
      this.$router.push({ name: "addSignup", params: { id: id, sign: 0 } });
    },
    handleEdit(id) {
      this.$router.push({ name: "editSignup", params: { id: id, sign: 0 } });
    },
    toFormConfig(id) {
      this.$router.push({ name: "designSignup", params: { id: id, sign: 1 } });
    },
    Administration(id) {
      this.$router.push({ name: "signupTube", params: { id: id } });
    },
    cancel() {
      this.openDialog = false;
    },
    submit(refName) {
      copyText(refName).then((valid) => {
        this.$message.success("复制成功");
        this.openDialog = false;
      });
    },
    // 获取精神列表
    getSpiritList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      getIndex(pageData)
        .then((res) => {
          const { data } = res;
          data.data.forEach((item) => {
            item.is_show = item.is_show ? true : false;
          });
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(id) {
      if (this.filterForm.tab === "normal") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        getSoftDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        getDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      getPutBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    editRow(row) {
      this.$router.push({
        name: "AddSpirit",
        params: { id: row.id, type: "edit" },
      });
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //组织名称
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.getSpiritList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getSpiritList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getSpiritList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getSpiritList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 修改列表相关状态
    changeStatus(e, key) {
      const data = {};
      data.id = e.id;
      if (key === "sort") {
        data[key] = e[key];
      } else {
        data[key] = e[key] ? 1 : 0;
      }
      if (key === "is_show") {
        // 更新显示状态
        updateShow(data);
      } else {
        // 更新排序状态
        updateSort(data);
      }
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getSpiritList(pageData);
    },
  },

  created() {
    this.getSpiritList(this.filterForm); //获取列表数据
  },
  components: { listFilter, Pagination, ListInputPopover },
};
</script>

<style lang="scss" scoped>
</style>
