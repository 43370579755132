import api from "@/base/utils/request";
import download from "@/base/utils/download";

//获取其他设置
export const getIndex = data => {
  return api({
    url: "/admin/signup/signup/index",
    method: "post",
    data
  });
};
//软删除接口
export const getSoftDelete = data => {
  return api({
    url: "/admin/signup/signup/softDelete",
    method: "post",
    data
  });
};
//还原
export const getPutBack = data => {
  return api({
    url: "/admin/signup/signup/putBack",
    method: "post",
    data
  });
};
//删除
export const getDelete = data => {
  return api({
    url: "/admin/signup/signup/del",
    method: "post",
    data
  });
};
//二维码
export const getQrcode = data => {
  return api({
    url: "/admin/signup/signup/qrcode",
    method: "post",
    data
  });
};

// 列表导出
export const orderExport = data => {
  return download({
    url: '/admin/signup/signup/document',
    method: 'post',
    data,
    download: true
  });
};